/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prázdna stránka"}>
        <SiteHeader />

        <Column className="pb--50 pt--80" name={"intro"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--10 pt--10 flex--center" style={{"maxWidth":1350}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":596}} content={"<span style=\"color: var(--color-dominant);\">Page title</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"content"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1350}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Heading"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1so983 pb--80 pt--80" name={"contact"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Make appointment</span>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Appointment"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":476}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Robert Kachlicka, M.D.</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Contact</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">722 Finch Street<br>510-851-40X4<br>info@vase-stranky.com<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}